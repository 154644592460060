import React from "react";
import styles from "./style.module.css";

const Oval = ({ children, height, color = "white", className }) => {
  return (
    <div style={{ height: parseInt(height), backgroundColor: color }} className={`${styles.ovalComponent} ${className}`}>
      {children}
    </div>
  );
};

export default Oval;
