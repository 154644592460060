import React from "react";
import styles from "./styles.module.css";

const VerticalLine = ({ height, imageLoaded, className, circle }) => {
  if (imageLoaded) height = height || "64vh";
  return (
    <div
      className={`${styles.vertical_line} ${
        circle ? styles.vertical_line_circle : ""
      } ${className} absolute top-0 w-px bg-white hidden md:block`}
      style={{ height: height || "0%" }}
    />
  );
};

export default VerticalLine;
