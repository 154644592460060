import React from "react";
import styles from "styles/pages/customers.module.css";

const CustomerTestimonialCard = ({ className, quote, author, image }) => {
  return (
    <div className="flex flex-col items-center md:items-left lg:flex-row">
      <div className="md:w-1/2 flex">
        <div style={{position: "relative", zIndex: "100"}} className="bg-white" >
        <div
          id="testimonial-card"
          
          className={`p-10 max-w-2xl rounded overflow-hidden shadow-lg`}
        >
          <p className={`text-gray-700 text-xs md:text-sm lg:text-md italic ${styles.lora_font}`}>&quot;{quote}&quot;</p>

          <p className="uppercase text-xs md:text-sm mt-5 md:mt-6" style={{ color: "#CFCFCF" }}>
            {author}
          </p>
        </div>
        </div>
 
      </div>
      <div className="md:w-1/2">
        <img className="lg:-ml-16" alt="" src={image} />
      </div>
    </div>
  );
};

export default CustomerTestimonialCard;
