import React from "react";
import { Link } from "gatsby";

const CyberHygieneProduct = ({ title, description, image, url, className }) => {
  return (
    <div className={`flex flex-col-reverse sm:flex-row ${className}`}>
      <div className="w-full sm:w-1/2 flex justify-center items-center sm:justify-start sm:items-start flex-col">
        <h3 className="font-bold text-3xl md:text-5xl leading-tight">{title}</h3>
        <p className="mt-2 text-center sm:text-left">
         {description}
        </p>
        <Link
          className="btn btn-orange-outline inline-block mt-6"
          to={url}
        >
          Learn More
        </Link>
      </div>
      <div className={`w-1/2 mx-auto sm:mx-0 sm:flex sm:justify-end`}>
        <img className="h-40 sm:h-64 mb-5 sm:mb-0" src={image} alt={""} />
      </div>
    </div>
  );
};

export default CyberHygieneProduct;
