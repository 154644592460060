import React from "react";
import styles from "styles/pages/customers.module.css";
import { graphql, Link, navigate } from "gatsby";
import DefaultLayout from "components/layout/default";
import { VerticalLine } from "components";
import { CompanyInfoCard } from "components/customers";
import get from "lodash/get";
import partition from "lodash/partition";

import grayRadisuSmTop from "images/radius-cap-gray-top-sm.svg";
import grayRadiusSmBottom from "images/radius-cap-gray-bottom-sm.svg";
import whiteTopRadius from "images/product-radius-top-cap.svg";
import whiteQuotes from "images/white-quotes.png";
import customerLogos from "images/logos/customer-compile-logos.png";

function filterTestimonials(testimonials, currentCompany) {
  const [mainTestimonial, otherTestimonails] = partition(
    testimonials,
    testimonial =>
      testimonial.node.frontmatter.company.toLowerCase() ===
      currentCompany.toLowerCase()
  );
  return {
    mainTestimonial,
    otherTestimonails
  };
}

const CustomerPage = props => {
  const [mainTestimonial, setMainTestimonial] = React.useState(null);
  const [otherTestimonials, setOtherTestimonials] = React.useState([]);
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  const { pageContent, latestTestimonials } = props.data;
  const { childPagesJson: pageData } = pageContent;
  const currentMainTestimonial = get(pageContent, "childPagesJson.company", "");

  React.useEffect(() => {
    setTimeout(() => {
      setMainImageLoaded(true);
    }, 750);
  }, []);
  React.useEffect(() => {
    const result = filterTestimonials(
      latestTestimonials.edges,
      currentMainTestimonial
    );
    setMainTestimonial(result.mainTestimonial[0]);
    setOtherTestimonials(result.otherTestimonails);
  }, []);

  const handleOnClickCard = url => () => {
    navigate(`/customers/${url}`);
  };
  return (
    <DefaultLayout
      location={props.location}
      title={"Horangi - Customer Stories"}
      description={
        "Walk the security journey of our customers with Horangi as their partner."
      }
    >
      <div className="w-full mb-32 lg:mb-10">
        <section
          className={`h-screen flex flex-col justify-center items-center ${styles.hero_image}`}
        >
          <h1 className="text-4xl lg:text-6xl font-bold text-center w-2/3 xl:max-w-5xl">
            {pageData.title}
          </h1>
          <h3 className="mt-4 text-center max-w-4xl mx-auto">
            {pageData.caption}
          </h3>
          <img src={whiteQuotes} alt="" className={`h-20 mt-5`} />
          <VerticalLine
            imageLoaded={mainImageLoaded}
            className="mt-160"
            circle
          />
        </section>
        <img
          src={whiteTopRadius}
          style={{ marginBottom: -1 }}
          alt=""
          className={`relative -mt-56 sm:-mt-56 lg:-mt-64 xxl:-mt-64 w-full sm:block pt-40`}
        />
        <div
          id="white-body"
          className="bg-white text-black pb-128 flex justify-center items-center flex-col"
        >
          {mainTestimonial === null ? null : (
            <section
              id="main-testimonial"
              className={`bg-white text-black w-192 sm:w-256 lg:w-288`}
            >
              <div className="flex flex-col lg:flex-row items-center justify-center text-black relative pt-40 p-16">
                <div className="w-1/2 flex justify-end relative -mt-20 lg:-mt-32">
                  <img
                    style={{ maxHeight: 400 }}
                    id="testimonial-image"
                    src={
                      mainTestimonial.node.frontmatter.mainImage.childImageSharp
                        .fluid.src
                    }
                    alt=""
                  />
                </div>
                <div className="xxl:w-1/2 flex items-center lg:-ml-20 relative sm:mt-5 lg:-mt-32 sm:p-10">
                  <div
                    onClick={handleOnClickCard(
                      mainTestimonial.node.fields.slug
                    )}
                    className="w-80 sm:w-96 sm:w-128 lg:w-128 xxl:w-160 xxl:max-w-xl rounded overflow-hidden shadow-lg p-5 sm:p-10 bg-white hover:shadow-dark cursor-pointer"
                  >
                    <img
                      src={
                        mainTestimonial.node.frontmatter.companyLogoImage
                          .childImageSharp.fluid.src
                      }
                      alt=""
                      id="company-logo"
                      className="mx-auto sm:mx-0"
                      style={{ width: 60 }}
                    />
                    <p className="text-sm font-bold mt-5">
                      {mainTestimonial.node.frontmatter.header}
                    </p>
                    <p className="text-sm mt-5 text-orange hover:font-bold w-1/5">
                      <Link
                        to={`/customers/${mainTestimonial.node.fields.slug}`}
                      >
                        Read More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}

          {otherTestimonials.length === 0 ? null : (
            <h1 className="p-10 sm:p-0 font-bold text-2xl text-center mt-56">
              EXPLORE OTHER CUSTOMER STORIES
            </h1>
          )}
          <section id="customer-testimonials" className="flex justify-center">
            {testimonialWrapper()}
          </section>
        </div>
        <img
          src={grayRadisuSmTop}
          alt=""
          className={`relative w-full -mt-40 lg:-mt-64`}
        />
        <section
          id="gray-radius"
          className="bg-white text-black flex flex-col justify-center items-center"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <h1 className="font-bold w-2/3 md:w-full text-center text-sm md:text-md lg:text-xl">
            TRUSTED BY ASIA’S INDUSTRY LEADERS AND FASTEST GROWING COMPANIES
          </h1>
          <div className="lg:max-w-6xl relative -mt-5 md:-mt-10">
            <img src={customerLogos} alt="" />
          </div>
        </section>
        <img
          src={grayRadiusSmBottom}
          alt=""
          className={`relative w-full`}
          style={{ marginTop: -1 }}
        />
      </div>
    </DefaultLayout>
  );

  function testimonialWrapper() {
    if (otherTestimonials.length === 0) {
      return null;
    } else {
      return (
        <div className="flex flex-col lg:flex-row lg:flex-wrap max-w-5xl">
          {renderTestimonials()}
        </div>
      );
    }
  }

  function renderTestimonials() {
    const splitPoint =
      otherTestimonials.length - (otherTestimonials.length % 3);
    const groupsOf3 = otherTestimonials.slice(0, splitPoint);
    const oddRows = otherTestimonials.slice(splitPoint);
    const groups = groupsOf3.map((item, index) => {
      const { header } = item.node.frontmatter;
      const { slug } = item.node.fields;
      const logo = item.node.frontmatter.companyLogoImage.childImageSharp.fluid;
      const mainImage = item.node.frontmatter.mainImage.childImageSharp.fluid;
      return (
        <div key={index} className="w-full lg:w-1/3 mt-10 p-5">
          <CompanyInfoCard
            image={mainImage.src}
            logo={logo.src}
            text={header}
            url={slug}
          />
        </div>
      );
    });
    // Logic for rows with less than 3
    let rowClass = "w-full flex";
    if (oddRows.length > 1) {
      rowClass = "w-full lg:w-1/2 flex";
    }
    const singles = oddRows.map((item, index) => {
      const { header } = item.node.frontmatter;
      const { slug } = item.node.fields;
      const logo = item.node.frontmatter.companyLogoImage.childImageSharp.fluid;
      const mainImage = item.node.frontmatter.mainImage.childImageSharp.fluid;
      return (
        <div key={index} className={`${rowClass} mt-10 p-5 justify-center`}>
          <CompanyInfoCard
            image={mainImage.src}
            logo={logo.src}
            text={header}
            url={slug}
          />
        </div>
      );
    });
    return [groups, singles];
  }
};

export const QUERY = graphql`
  {
    pageContent: file(relativePath: { eq: "pages/customers.json" }) {
      childPagesJson {
        title
        caption
        company
      }
    }
    latestTestimonials: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/customer-testimonials//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 400)
          frontmatter {
            header
            company
            companyLogoImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: PNG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            mainImage {
              childImageSharp {
                fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomerPage;
