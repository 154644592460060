import React from "react";
import { Link } from "gatsby";

const CompanyInfoCard = ({ image, logo, text, url, key, className, name }) => {
  return (
    <Link to={`/customers/${url}`}>
      <div
        key={key}
        className={`rounded overflow-hidden hover:shadow-dark cursor-pointer p-10 bg-white ${className}`}
      >
        <img
          src={image}
          alt=""
          style={{
            minHeight: 175,
            maxHeight: 175,
            objectFit: "cover",
            width: "100%",
            height: "100%",
            overflow: "hidden"
          }}
        />

        <img className="mt-5" src={logo} alt="" style={{ maxWidth: 60 }} />
        <p className="mt-2 mb-2" style={{ minHeight: 120 }}>
          {text}
        </p>
        <p className="text-sm text-orange hover:font-bold">
          <Link to={`/customers/${url}`}>Read More</Link>
        </p>
        <div></div>
      </div>
    </Link>
  );
};

CompanyInfoCard.defaultProps = {
  className: "w-80 h-112"
};

export default CompanyInfoCard;
