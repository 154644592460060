import React from "react";

const CompanyLogo = ({ image, className }) => {
  return (
    <img
      className={`mx-auto sm:mr-10 mt-3 sm:-mt-0 ${className}`}
      src={image}
      alt=""
    />
  );
};

export default CompanyLogo;
